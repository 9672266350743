.logo {
  margin-right: 38px;
}

.menu {
  width: 100%;
}

.subMenu {
  width: auto;
  margin-left: 12px;
  margin-right: 12px;
}

.divider {
  width: 100%;
  height: 100px;
  background-color: aqua;
  margin: 22px;
}
